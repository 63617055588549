import React, { Component } from 'react';
import './App.scss';

import { Provider } from "react-redux";
import configureStore from './configureStore';

import Main from './components/Main';
import Header from './components/Header';
import Footer from './components/Footer';


const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App text-center bg-dark">
          <Header />
          <Main />
          <Footer />
        </div>
      </Provider>
    );
  }
}

export default App
