import React from "react";

import Button from '../buttons/Button';

const SubmitButton = (props) => {
  let {isSending} = props;
  return (
    <Button className="btn-success btn-block" type="submit" disabled={isSending}>
      {isSending === true
        ? (
          <span>
            <span className="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
            <span className="sr-only">Loading...</span>
          </span>
        )
        : 'Submit'
      }
    </Button>
  )
}

export default SubmitButton
