import React, { Component } from 'react';
import 'bootstrap/js/dist/collapse';

import Logo from '../Logo';
import NavLink from './NavLink';
import routes from '../../modules/routes';


class Navbar extends Component{
  constructor(props){
    super(props);
    this.buttonRef = React.createRef();
    this.handleClickOutsideButton = this.handleClickOutsideButton.bind(this);
  }

  componentDidMount() {
    // link works as previously because listens touchend
    document.addEventListener('touchend', this.handleClickOutsideButton);
  }

  /* Close navbar when
   * touch smth outside of nav button
   */
  handleClickOutsideButton(e) {
    let button = this.buttonRef.current;
    let shown = button.getAttribute("aria-expanded")
    // Hide #navbarNav
    // when touch ouside the button
    // even when it moves to another page with navbar link
    if (
      !button.contains(e.target)
      && shown === "true"
    ) {
      button.click();
    }
  }

  render(){
    return(
      <nav className="row navbar navbar-expand-md navbar-dark justify-content-end">
        <Logo className="navbar-brand mr-auto" />
        <button className="navbar-toggler"
          type="button" data-toggle="collapse"
          data-target="#navbarNav"
          ref={this.buttonRef}>
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse flex-grow-0"
          id="navbarNav">
          <ul className="navbar-nav text-right">
            <NavLink to={routes.home}>Home</NavLink>
            <NavLink to={routes.portfolio}>Portfolio</NavLink>
            <NavLink to={routes.contact}>Contact</NavLink>
            <NavLink to={routes.about}>About</NavLink>
          </ul>
        </div>
      </nav>
    );
  }  
}

export default Navbar
