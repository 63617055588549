import LabeledBaseField from './LabeledBaseField';


class BaseInput extends LabeledBaseField {
  static defaultProps = LabeledBaseField.defaultProps
  
  child = 'input'
}


export default BaseInput
