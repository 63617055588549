import React from "react";

import BaseField from './BaseField';


class LabeledBaseField extends BaseField {
  static defaultProps = BaseField.defaultProps

  render(){
    // render simple tag without label
    if (!this.props.label){
      return super.render();
    }
    // wrap into div with label
    return (
      <div className="form-group">
        <label>
          {this.props.label}
          {super.render()}
        </label>
      </div>
    )
  }
}


export default LabeledBaseField
