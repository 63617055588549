import FetchComponent from './FetchComponent';


class FetchOnComponentDidMount extends FetchComponent {
    componentDidMount(){
      this.fetchData()
    } 
}

export default FetchOnComponentDidMount
