import React from 'react';

import HireBanner from './banner/HireBanner'


function withSidebar(Component){
  return function WithSidebar(props){
    return (
      <div>
        <Component {...props}/>
        <HireBanner/>
      </div>
    )
  }
}

export default withSidebar
