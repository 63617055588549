import React from 'react';

import Button from '../buttons/Button';
import routes from '../../modules/routes';
import withFetching from '../../modules/api';
import withSidebar from '../withSidebar';


const API_NAME = 'interest-list';


function Home(props) {
  let name = 'Andrew Dybov';
  let interests = props.getApi().data;

  let btn_class = "btn-lg btn-primary"
  return (
    <section className="align-items-center Home">
      <div>
        <h1>{name}</h1>
        {interests && <ul>{interests.map((el, idx) => <li key={idx}><b>{el.title}</b></li>)}</ul>}
      </div>
      <div>
        <Button className={btn_class} to={routes.portfolio}>
          Projects
        </Button>
        <Button className={btn_class} to={routes.about}>
          About
        </Button>
      </div>
    </section>
  )
}

export default withSidebar(withFetching(API_NAME)(Home))
