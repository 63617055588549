import React from 'react';

import withFetching from '../modules/api';
import withSidebar from './withSidebar';


const API_NAME = 'about-me';


function AboutMe(props){
  let {description} = props.getApi().data || "";

  return (
    <section id='AboutMeDesc'>
      <h1>About Me</h1>
      <p
        className='text-left'
        id='AboutMeDescription'
        dangerouslySetInnerHTML={{__html: description}}/>
    </section>
  );
}

export default withSidebar(withFetching(API_NAME)(AboutMe))
