import React from 'react';

import Button from '../buttons/Button';
import routes from '../../modules/routes';


function NotFound(props){
  return (
    <div>
      <h2>404 Not Found</h2>
      <p>Please go to Home page by clicking button below</p>
      <Button className='btn-outline-primary' to={routes.home}>Home</Button>
    </div>
  );
}

export default NotFound
