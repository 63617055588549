import React from 'react';


class Loading extends React.Component {
  static defaultProps = {size: undefined}
  constructor(props){
    super(props)
    this.state = {size: props.size}
  }
  componentDidMount(){
    var {size} = this.state;

    let parentsWidth = this.refs.child.parentNode.clientWidth

    if (parentsWidth < size){
      this.setState({size: parentsWidth});
    }
  }

  render(){
    let width, height;
    let styles = {}

    let {size} = this.state;
    if (size!==undefined){
      width = height = size;
      styles = {width, height}
    }

    var classname = (this.props.className || "")
      + " Loading spinner-border";

    return (
      <div {...this.props} ref='child' className={classname} role="status" style={styles}>
        <span className="sr-only">Loading...</span>
      </div>
    )
  }
}

export default Loading
