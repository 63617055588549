import React, {Component} from 'react';

import { Switch, withRouter, Route } from 'react-router-dom';

import AboutMe from './AboutMe';
import HireMe from './hire/HireMe';
import Home from './home/Home';
import NotFound from './errors/NotFound';
import Portfolio from './portfolio/Portfolio';
import routes from '../modules/routes';

import { TransitionGroup, CSSTransition } from "react-transition-group";


class Main extends Component{
  componentDidUpdate(prevProps) {
    // It will scroll page to top when location pathname will be changed
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Timeout is neccesary to scroll after all children render with transitions
      setTimeout(() => {
        window.scrollTo({
          top: '#top',
          behavior: "smooth",
        })
      }, 300)
    }
  }
  render(){
    let props = this.props;
    let main_class = 'col-lg-12';
    if (props.location.pathname===routes.portfolio){
      main_class = 'col-12 Portfolio';
    }

    return (
    <main className={main_class}>
      <TransitionGroup className="col-12 content transition-group">
        <CSSTransition
          key={props.location.key}
          timeout={{ enter: 300, exit: 300 }}
          classNames={'fade'}
        >
          <Switch location={props.location}>
            <Route exact path={routes.contact} component={HireMe}/>
            <Route exact path={routes.home} component={Home}/>
            <Route exact path={routes.portfolio} component={Portfolio}/>
            <Route exact path={routes.about} component={AboutMe}/>
            <Route component={NotFound}/>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </main>
    )
  }
}

export default withRouter(Main)
