import React from 'react';
import PropTypes from 'prop-types';


class Technologies extends React.Component {
  render(){
    const {technologies} = this.props;

    if (!technologies || !technologies.length){
      return null;
    }

    return (
      <React.Fragment>
        <h6>Technologies</h6>
        <ul className='technologies'>
          {technologies.map((technology, idx) => {
            return (
              <li key={idx} className='technology badge badge-pill badge-primary mr-3'>
                {technology}
              </li>
            );
          })}
        </ul>
        <hr/>
      </React.Fragment>
    )
  }
}


Technologies.propTypes = {
  technologies: PropTypes.array.isRequired,
};


export default Technologies
