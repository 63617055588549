import React, { Component } from 'react';

import Navbar from './nav/Navbar';


class Header extends Component{
  render(){
    return (
      <React.Fragment>
        <header className='fixed-top'>
          <Navbar/>
        </header>
      </React.Fragment>
    );
  }
}

export default Header