import React, { Component } from 'react';

import Link from './Link';
import routes from '../modules/routes';


class Logo extends Component{
  render(){
    return (
      <Link to={routes.home} className={`d-flex align-items-center ${ this.props.className } `}>
        <h1 className="badge mb-0">
          Andrew Dybov<br/>
          <small>Software Developer</small>
        </h1>
      </Link>
    );
  }
}

export default Logo
