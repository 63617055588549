import React from 'react';


const ProjectImage = (props) => {
  var style = {};
  const {image} = props;

  if (image) {
    style.backgroundImage = `url('${image}')`;
  }

  return (
    <div style={style} className="ProjectImage col-md-4">
      <span className="sr-only">Project image</span>
    </div>
  )
}

export default ProjectImage
