import classNames from 'classnames';
import React from 'react';
import BaseButton from './BaseButton'


function Button(props) {
  const cssclasses = classNames(
    'Button',
    props.className
  );
  return (
    <BaseButton  {...props} className={cssclasses} />
  );
}

export default Button
