import LabeledBaseField from './LabeledBaseField';


class DefaultTextArea extends LabeledBaseField {
  static defaultProps = Object.assign({}, LabeledBaseField.defaultProps)
  child = 'textarea'
}
DefaultTextArea.defaultProps.type = null;

export default DefaultTextArea
