import React from 'react';


const Footer = props => {
  const current_year = (new Date()).getFullYear();
  const is2018 = current_year===2018;

  return (
    <footer className="bg-secondary text-light">
      <p className="col-12"><b>
      Copyright Ⓒ 2018 {!is2018 && `- ${current_year}`} Andrew Dybov
      </b></p>
    </footer>
  );
}

export default Footer
