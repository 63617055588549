import React, { Component } from 'react';

import Project from './Project';
import withFetching from '../../modules/api';
import withSidebar from '../withSidebar';
import sortProjects from './sortProjects';


const API_NAME = 'project-list';


class Portfolio extends Component {
  render(){
    return (
      <section>
        <h1>Portfolio</h1>
        {this.renderProjects()}
      </section>
    );
  }

  renderProjects(){
    let projects = this.props.getApi().data;

    if (!projects){
      return <p>No projects are available</p>;
    }

    return projects.map((project, idx)=>{
      return <Project data={project} key={idx}/>
    })
  }
}

export default withSidebar(
  withFetching(
    API_NAME
  )(
    Portfolio,
    // eslint-disable-next-line
    ...[,,,], //Skip other named arguments with defaults
    sortProjects //Provide data preprocessor
  )
)
