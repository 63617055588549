import React, { Component } from "react";

import HireForm from './HireForm';
import SocialLinks from './SocialLinks';


class HireMe extends Component{
  render(){
    return (
      <section className="Contact">
        <h1>Contact me</h1>
        <ul>
          <li>
            <SocialLinks/>
          </li>
        </ul>
        <HireForm/>
      </section>
    );
  }
}

export default HireMe
