import React, {Component} from 'react';
import PropTypes from 'prop-types';


const getStyle = (x, styleProp) => {
    var y;
    if (x.currentStyle)
        y = x.currentStyle[styleProp];
    else if (window.getComputedStyle)
        y = document.defaultView.getComputedStyle(
          x, null).getPropertyValue(styleProp);
    return y;
}


class ReadMore extends Component {
  constructor(props){
    super(props);
    this.state = {
      expand: false,
      expandHeight: 0,
      collapsedMaxHeight: 0,
      lineHeight: 0,
      expandable: true,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.renderElement = this.renderElement.bind(this);
    this.resizeElement = this.resizeElement.bind(this);

    this.myRef = React.createRef();
  }

  resizeElement(){
    var expandable;
    let node = this.myRef.current;
    if (node){
      this.setState({expandHeight: 'auto'});

      const expandHeight = node.clientHeight,
            lineHeight = parseInt(getStyle(node, 'line-height')),
            collapsedMaxHeight = this.props.collapsedMaxRows*lineHeight,
            currentExpandHeight = this.state.expandHeight,
            currentCollapsedMaxHeight = this.state.collapsedMaxHeight;
      
      if (collapsedMaxHeight>0
          && expandHeight>0
          && expandHeight-collapsedMaxHeight<lineHeight){
        expandable = false;
      } else {
        expandable = true;
      }

      if (currentExpandHeight!==expandHeight){
        this.setState({expandHeight});
      }
      if (collapsedMaxHeight!==currentCollapsedMaxHeight){
        this.setState({collapsedMaxHeight, expandable});
      }
    }
  }

  toggleExpand(e){
    e.preventDefault();

    var {expand} = this.state,
        {top} = this.myRef.current.getBoundingClientRect(),
        {topOffsetForScroll} = this.props;

    top = top + document.documentElement.scrollTop - topOffsetForScroll;

    expand = !expand;
    this.setState({expand});

    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }

  componentDidMount(){
    this.resizeElement();
    window.addEventListener("resize", this.resizeElement);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeElement);
  }

  render(){
    const {children} = this.props,
          {expandable} = this.state;

    if (expandable===false){
      return <p>{children}</p>;
    }

    return this.renderElement(children);
  }

  renderElement(children){
    const {
            read_less,
            read_more,
            className,
          } = this.props,
          {
            expand,
            expandHeight,
            collapsedMaxHeight,
          } = this.state;

    var less_or_more,
        extraClassName,
        style = {
          transition: "max-height .5s ease-in-out",
          overflow: "hidden",
          marginBottom: 0,
        };

    if (expandHeight>0){
      if (expand===false){
        less_or_more = read_more;
        style.maxHeight = collapsedMaxHeight;
        extraClassName = 'collapsed';
      } else {
        less_or_more = read_less;
        style.maxHeight = expandHeight;
        extraClassName = 'expanded';
      }
    }

    const classNames = (className || '')
                        + (extraClassName || '');

    return (
      <div className="MoreAndLessComponent text-left">
        <p 
          className={classNames}
          ref={this.myRef}
          style={style}
        >
          {children}
        </p>
        <button
          className='btn btn-link'
          onClick={this.toggleExpand}
        >{less_or_more}</button>
      </div>
    )
  }
}

ReadMore.propTypes = {
  collapsedMaxRows: PropTypes.number,
  children: PropTypes.string.isRequired,
  read_less: PropTypes.string,
  read_more: PropTypes.string,
  topOffsetForScroll: PropTypes.number,
};
ReadMore.defaultProps = {
  collapsedMaxRows: 2,
  read_less: "Close",
  read_more: "Read More",
  topOffsetForScroll: 0,
};

export default ReadMore
