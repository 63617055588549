import React, { Component } from "react";

import withFetching from '../../modules/api';
import Loading from '../Loading';

const API_NAME = 'social-links';


class SocialLinks extends Component {
  render(){
    let links = this.props.getApi().data || [];

    if (links.length === 0 || !links){
      return null;
    }
    return (
      <ul className='SocialLinks'>
        {links.map((link, idx)=>{
          return (
            <li key={idx}>
              <a href={link.url} target='_blank' rel="noopener noreferrer">
                <img src={link.image_thumbnail} alt={link.title}/>
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
}


export default withFetching(API_NAME)(SocialLinks, <Loading size={31} />)