import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

// trigger for changing Router's Link to a and backward
const global_a_using = false

/* Link component is wrapper for both <a/> and Router's <Link/>
 * For force change to <a href='smth'>... use <Link atag='true' to='smth>... 
 */
function Link(props) {
  if (props.atag || global_a_using){
    // elsint show that <a/> must have children
    // but they will be provided with props
    //
    // eslint-disable-next-line 
    return <a {...props} href={ props.to }/>;
  }
  return <RouterLink {...props}/>
}

export default Link
