export default (projects) => {
  projects = projects.slice();
  const getDates = (el) => {
    const yearMonth = date => {
      if (!date) return [9999, null];
      date = new Date(date);
      return [date.getFullYear(), date.getMonth() + 1];
    }
    
    var {start, end} = el;
    
    return [
      yearMonth(start),
      yearMonth(end),
    ];
  }
  const newFirst = (a, b) => {
     if (a[0]>b[0]) return -1;
     else if (a[0]<b[0]) return 1;
     if (a[1]>b[1]) return -1
     else if (a[1]<b[1]) return 1;
     return 0;
  }

  projects.sort((a, b) => {
    const [a_start, a_end] = getDates(a);
    const [b_start, b_end] = getDates(b);
    var end = newFirst(a_end,b_end);
    if (end===0){
      return newFirst(a_start,b_start);
    }
    return end;
  })
  return projects;
}