import React from 'react';

import Button from '../buttons/Button';
import routes from '../../modules/routes';


function HireBanner(props){
  return (
    <div className="col-12 align-items-center">
      <h1>Have a question?</h1>
      <Button className="btn-success btn-lg" to={routes.contact}>
        Get in touch
      </Button>
    </div>
  );
}

export default HireBanner
