import classNames from 'classnames';
import React from 'react';

import Link from '../Link';


function BaseButton(props) {
  const cssclasses = classNames(
    'btn',
    props.className
  );
  return (
    props.to
      ? <Link {...props} className={cssclasses} role="button"/>
      : <button {...props} className={cssclasses} />
    );
  }

export default BaseButton
