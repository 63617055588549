import React, { Component, Fragment } from "react";


class BaseField extends Component {
  static defaultProps = {
    errors: null,
    type: 'text',
    defaultValue: "",
    defaultClassName: 'form-control',
  }

  constructor(props){
    super(props);

    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e){
    let new_value = e.target.value;
    let current_value = this.state.value;
    if (new_value !== current_value){
      this.setState({value: new_value})
    } 
  }

  render(){
    var {value} = this.state;
    var {
      errors,
      defaultValue,
      type,
      className,
      defaultClassName,
      ...props
    } = this.props;
    if (value===undefined){
      value = defaultValue;
    }
    let child = this.child;
    let isError = errors && errors.length > 0
    className = (className
                  ? `${className} ${defaultClassName}`
                  : defaultClassName
                )
                + (isError
                    ? ' is-invalid error'
                    : ''
                  );

    return (
      <Fragment>
        {React.createElement(child, {
          ...props,
          className,
          type,
          value,
          onChange:this.handleChange,
        })}
        {isError && (
          <ul className="errorlist alert-danger">
            {errors.map((error, idx) => (
              <li key={idx}>{error}</li>
            ))}
          </ul>
        )}
      </Fragment>
    )
  }
}

export default BaseField
