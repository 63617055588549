import React from 'react';
import { withRouter } from 'react-router-dom';

import Link from '../Link';


function NavLink(props) {
  let disabled = props.disabled
    ? 'disabled'
    : ''
  var {location, to, children, staticContext, ...rest} = props
  let active = location.pathname === to
    ? 'active'
    : ''
  return (
    <li className={`nav-item ${ active }`}>
      <Link {...rest} children={props.children} to={to}
        className={`nav-link ${ disabled } ${ props.className }`} />
    </li>
  )
}

export default withRouter(NavLink)
