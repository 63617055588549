import React, { Component } from "react";

import TextInput from '../form_elements/TextInput';
import EmailInput from '../form_elements/EmailInput';
import DefaultTextArea from '../form_elements/DefaultTextArea';
import SubmitButton from './SubmitButton';

import routes from "../../modules/routes";
import { getCookie } from "../../modules/api";
import { isClientError } from "../../modules/status";


var csrftoken = getCookie('csrftoken');

class HireForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      isSent: false,
      error: null,
      isSending: false,
      input_errors: {},
      data: {},
    }
    this.handleClienErrors = this.handleClienErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleResponse = this.handleResponse.bind(this)
    this.postData = this.postData.bind(this)
  }

  handleClienErrors(status, data){
    this.setState({input_errors: data})
  }

  handleChange(e){
    let {name, value} = e.target;
    var newState =  JSON.parse(JSON.stringify(this.state));
    newState.data[name] = value;
    this.setState({...newState});
  }

  handleSubmit(e){
    e.preventDefault()
    this.setState({
      isSending: true,
    })
    this.postData()
  }

  async handleResponse(response){
    let status = response.status;
    let json_data = await response.json();
    this.setState({
      isSending: false,
    })
    if (isClientError(status)) {
      this.handleClienErrors(status, json_data);
    } else if (status === 201){
      this.setState({
        isSent: true,
      })
    } else {
      this.setState({
        error: `Unexpected response status "{status}"`,
      })
    }
  }

  postData(){
    let formData = this.state.data;
    let token = csrftoken;
    fetch(routes.contact, {
      method: "post",
      credentials: "same-origin",
      headers: {
          "X-CSRFToken": token,
          "Accept": "application/json",
          "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    }).then(
      this.handleResponse
    ).catch((error)=>{
      this.setState(
        {
          error,
          isSending: false,
        },
      )
      console.error('Request failed', error)
    })
  }

  render(){
    let {isSent, isSending, error} = this.state;
    if (error) {
      return (
        <div className="Error">
          <h2>Sorry, error was occur.</h2>
          <p>Please try later or use sources placed above to contact.</p>
        </div>
      )
    } else if (isSent){
      return (
        <div className="Sent">
          <h2>Thank you for your interest!</h2>
          <p>I'll answer you soon.</p>
          <p>Please feel free to contact me throught sources listed above.</p>
        </div>
      )
    }
    let errors = this.state.input_errors;
    return (
      <div>
        <h2>Or fill this form</h2>
        <form
          className="ContactForm"
          onSubmit={this.handleSubmit}
          action={routes.contact}
          method="post"
          onChange={this.handleChange}
        >
          <input name='csrfmiddlewaretoken' value={csrftoken} hidden readOnly/>
          <TextInput name="name" placeholder="Name" errors={errors['name']} required/>
          <EmailInput name="email" placeholder="e-mail" errors={errors['email']} required/>
          <DefaultTextArea name="message" placeholder="Message" errors={errors['message']} required/>
          <SubmitButton isSending={isSending}/>
        </form>
      </div>
    )
  }
}

export default HireForm
