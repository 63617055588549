import React from 'react';
import formatDate from './formatDate';

import Technologies from './Technologies';
import ProjectImage from './ProjectImage';
import ReadMore from '../ReadMore';

import AppStyles from '../../App.scss';

let minHeaderHeight = parseInt(AppStyles.minHeaderHeight);


function Project(props){
  let {title, start, end, description, technologies, image} = props.data;
  end = formatDate(end);
  start = formatDate(start);
  return (
    <div className='Project card row mb-3 border-secondary'>
      <ProjectImage image={image} />
      <div className='card-body col-md-8'>
        <h3 className='card-title'>
          {title} 
        </h3>
        <h6 className='card-subtitle mb-4 text-muted'>{start} - {end || "present"}</h6>
        <hr/>
        <div className='card-text'>
          <Technologies technologies={technologies}/>
          <ReadMore topOffsetForScroll={minHeaderHeight}>
            {description || "Description is not provided."}
          </ReadMore>
        </div>
      </div>
    </div>
  );
}

export default Project
